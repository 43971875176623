(function($){
	$(function() {

		$('#go-to-agenda').click(function(){
			$('html, body').animate({
				scrollTop: $('#agenda-control').offset().top - 80
			}, 1000, function() {

			});
		});

		if($('#locatie-control').length > 0) {
			var locatie_app = new Vue({
				el: '#locatie-control',
				mounted: function () {
					var self = this;

					// //fetch the current data
					self.fetchData();

					//attach the select
					handleSelect();

					$('.md-select').each(function (index, element) {

						$(element).find('ul li').on('click', function () {
							self.getFilter('grootte');
							self.getFilter('type');
							self.getFilter('locatie');

							//fetch the data
							self.fetchData();
						});
					});
				},
				data: {
					items: {},
					filters: {
						grootte: [],
						type: [],
						locatie: []
					}
				},
				// define methods under the `methods` object
				methods: {
					fetchData: function(){
						var self = this;

						//set the query string
						//self.setParam('params', self.currentDate.format('YYYY-MM-DD'));

						//show the loader
						$('.schedule-loader').show();

						//get the data
						$.get('/locatie-data/ajax-locatie-overzicht', {
							grootte: self.filters.grootte.join('|'),
							locatie: self.filters.locatie.join('|'),
							type: self.filters.type.join('|')
						}, function(data){
							//assing the items0
							self.items = JSON.parse(data);

							//hide the loader
							$('.schedule-loader').fadeOut(function(){
								progressiveImageLoader();
							});
						});
					},
					getFilter: function(type){
						var self = this;
						self.filters[type] = [];
						$('#'+type+'-filter li[aria-selected="true"]').each(function(){
							self.filters[type].push($(this).attr('id'));
						});

					},
					getParam: function(key) {
						var query =  document.location.search.replace('?', '').split('&');
						var value = '';
						$.each(query, function(index, val) {
							var find = val.split('=');
							if(find[0] === key) {
								value = find[1];
							}
						});

						return value;
					},
					setParam: function(key, value) {
						var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
							urlQueryString = document.location.search,
							newParam = key + '=' + value,
							params = '?' + newParam;

						// If the "search" string exists, then build params from it
						if (urlQueryString) {
							var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
							var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

							if( typeof value === 'undefined' || value == null || value === '' ) { // Remove param if value is empty
								params = urlQueryString.replace(removeRegex, "$1");
								params = params.replace( /[&;]$/, "" );

							} else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
								params = urlQueryString.replace(updateRegex, "$1" + newParam);

							} else { // Otherwise, add it to end of query string
								params = urlQueryString + '&' + newParam;
							}
						}

						// no parameter was set so we don't need the question mark
						params = params === '?' ? '' : params;

						window.history.replaceState({}, "", baseUrl + params);
					}
				}
			});

			//event when clicking the calendar and select a date from it.
			// $('.select-week').datepicker().on('changeDate', function(e) {
			// 	agenda_app.setDate(e.date);
			// 	agenda_app.updateWeekNrs();
			// 	agenda_app.fetchData();
			// });
		}
		
	});
})(jQuery);
